
























































































































import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import CCustomSelectHeader from '~/components/shared/configurable/form/select/custom/CCustomSelectHeader.vue'
import { DropdownDirection } from '~/components/shared/configurable/form/select/types'
import { Option } from '~/models/shared/types'
import CCustomSelectDropdown from '~/components/shared/configurable/form/select/custom/dropdown/CCustomSelectDropdown.vue'
import CCustomSelectNativeSelect from '~/components/shared/configurable/form/select/custom/CCustomSelectNativeSelect.vue'
import CCustomSelectDropdownDefaultOptionSingle from '~/components/shared/configurable/form/select/custom/dropdown/CCustomSelectDropdownDefaultOptionSingle.vue'
import { getParentPosition } from '~/utils/dom'
import { textExistsInString } from '~/utils/string'
import { mapGetters } from 'vuex'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { v4 as uuid } from 'uuid'
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { vue3Model } from '~/utils/nuxt3-migration'

export default defineComponent({
  model: vue3Model,
  components: {
    CCustomSelectHeader,
    CCustomSelectDropdown,
    CCustomSelectNativeSelect,
    CCustomSelectDropdownDefaultOptionSingle
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showDropdownFooter: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * A text to show on the header, prepended to the selected item/items
     * @prop {String} headerPreText
     */
    headerPreText: {
      type: [String, null],
      required: false,
      default: null
    },
    headerTitle: {
      type: [String, null],
      required: false,
      default: null
    },
    /**
     * Optional classes that get applied to the header.
     * @prop {String} headerClasses
     */
    headerClasses: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Optional prefix for the header text when placeholder is not showing.
     * For now it only works when select is not multiple
     * @prop {String} headerPrefix
     */
    headerPrefix: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Optional suffix for the header text when placeholder is not showing.
     * For now it only works when select is not multiple
     * @prop {String} headerSuffix
     */
    headerSuffix: {
      type: String,
      required: false,
      default: ''
    },
    caretIcon: {
      type: [Object, null] as PropType<IconDefinition | null>,
      required: false,
      default: null
    },
    dropdownClass: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Whether or not to show the All Option button in the dropdown.
     * @prop {Boolean} showAllButton
     */
    showAllButton: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * The initial value of the select
     * @prop {[Object, String, Number, Array]} value
     */
    modelValue: {
      type: [String, Number, Array, Object],
      required: false,
      default: null
    },
    multiSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * The placeholder to show in the select's header.
     * @prop {String} placeholder
     */
    placeholder: {
      type: String,
      default: ''
    },
    /**
     * The placeholder to show in the search input.
     * @prop {String} searchPlaceholder
     */
    searchPlaceholder: {
      type: String,
      default: ''
    },
    /**
     * Whether the select starts with the dropdown open.
     * @prop {Boolean} dropdownOpen
     */
    dropdownOpen: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Whether the select can be searched using a search input
     * @prop {Boolean} searchable
     */
    searchable: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * The array of available options to show in the dropdown
     * @prop {Array} options
     */
    options: {
      type: Array as PropType<Option[]>,
      required: false,
      default() {
        return []
      }
    },
    /**
     * the field which contains each option's value. Defaults to 'value' if not provided.
     * @prop {String} valueField
     */
    valueField: {
      type: String,
      required: false,
      default: 'value'
    },
    /**
     * the field which contains each option's text. Defaults to 'text' if not provided.
     * @prop {String} textField
     */
    textField: {
      type: String,
      required: false,
      default: 'text'
    },
    /**
     * This property dictates whether or not an already selected option can be deselected
     * @prop {Boolean} canDeselectSingle
     */
    canDeselectSingle: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String as PropType<'sm' | 'md' | 'lg'>,
      required: false,
      default: 'md'
    },
    noResultsMessage: {
      type: String,
      default: ''
    },
    checkboxDividerIndices: {
      type: Array as PropType<number[]>,
      required: false,
      default() {
        return []
      }
    },
    state: {
      type: Boolean,
      default: null
    },
    warning: {
      type: Boolean,
      default: false
    },
    noPortal: {
      type: Boolean,
      required: false,
      default: false
    },
    withMakeLogo: {
      type: Boolean,
      default: false
    },
    hiddenSingleSelectDivider: {
      type: Boolean,
      default: true
    },
    withMakeLogoCount: {
      type: Boolean,
      default: true
    },
    mainTextClasses: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: undefined
    },
    inGroup: { type: Boolean, default: false }
  },
  data() {
    return {
      dropdownPositionalCssRules: {
        height: null,
        left: null,
        top: null,
        width: null
      },
      q: '',
      internalDropdownOpen: this.dropdownOpen,
      dropdownDirection: DropdownDirection.Below,
      dropdownId: uuid()
    }
  },
  computed: {
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile',
      isTablet: 'isTablet'
    }),
    showNativeSelect() {
      return this.isMobile || this.isTablet
    },
    selectedOptions: {
      get() {
        if (!this.internalValue) {
          return []
        }

        return this.internalOptions.filter(option =>
          this.internalValue.includes(option?.value)
        )
      },
      set() {}
    },
    internalValue() {
      if (!this.modelValue) {
        return []
      }
      return Array.isArray(this.modelValue)
        ? this.modelValue
        : [this.modelValue]
    },
    internalOptions() {
      if (this.valueField || this.textField) {
        return this.options.map(this.mapToOption)
      } else return this.options
    },
    optionScopedSlot() {
      return this.$scopedSlots.option
    },
    filteredOptions() {
      if (this.q) {
        return this.internalOptions.filter(option =>
          textExistsInString(this.q, option.text)
        )
      } else {
        return this.internalOptions
      }
    },
    allValues() {
      return this.internalOptions.map(option => option.value)
    },
    allAreSelected() {
      return this.internalValue.length === this.internalOptions.length
    },
    disabledOptions(): Set<Option['value']> {
      const disabled = new Set<Option['value']>()
      for (const option of this.internalOptions) {
        option.disabled && disabled.add(option)
      }
      return disabled
    },
    nothingSelected() {
      return (
        this.internalValue === null ||
        (Array.isArray(this.internalValue) && this.internalValue.length === 0)
      )
    }
  },
  mounted() {
    this.updateDropdownPosition()
  },
  methods: {
    clearSearchInput() {
      this.q = ''
    },
    handleClearAll() {
      this.emitValue([])
    },
    handleDeselectOption(option: Option) {
      // TODO: F: handle disabled options.
      this.emitValue(this.internalValue.filter(value => value !== option.value))
    },
    handleDeselectAll() {
      this.emitValue(this.canDeselectSingle ? '' : [])
    },
    handleSelectAll(selectAllChecked) {
      if (selectAllChecked) {
        this.emitValue(this.allValues)
      } else {
        this.emitValue([])
      }
    },
    handleSearch(text) {
      this.q = text
      this.$emit('searchChange', text)
    },
    emitValue(value) {
      this.$emit('input', value)
      this.$emit('update:modelValue', value)
      this.$emit('change', value)
    },
    handleMultiSelect(value) {
      // TODO: F: handle disabled options. Should they be filtered out from emitted ones, or should nothing be emitted at all?
      this.emitValue(value)
    },
    handleSelect(value) {
      if (this.disabledOptions.has(value)) {
        return
      }
      this.emitValue(value)
      this.internalDropdownOpen = false
    },
    mapToOption(option): Option {
      return {
        ...option,
        text: option[this.textField],
        value: option[this.valueField],
        count: option.count
      }
    },
    toggleDropdown() {
      this.internalDropdownOpen ? this.closeDropdown() : this.openDropdown()
    },
    async openDropdown() {
      this.internalDropdownOpen = true
      this.$emit('show')
      await this.$nextTick()
      await this.updateDropdownPosition()
    },
    closeDropdown() {
      this.$emit('hide')
      this.internalDropdownOpen = false
    },
    async updateDropdownPosition() {
      await this.$nextTick()
      const parentPos = getParentPosition(
        this.$refs.carzillaSelect,
        this.$refs.dropdown?.$el
      )

      if (!parentPos || this.noPortal) {
        // Throws parentPos undefined in dsite search page if unguarded.
        return
      }

      this.dropdownPositionalCssRules = parentPos.style

      this.dropdownDirection = parentPos.openDirection
    }
  }
})
